import React from 'react'
import Layout from '../components/layout'






const RegisterIndex = (props) => {

    
    


    return (
      <Layout location={props.location}>
          <div className='empty_box'></div>
        <iframe title='register' frameborder="0" height="900px" scrolling="yes" src="https://manegeplan.azurewebsites.net/inschrijvenV1.php?id=81856075" width="100%"></iframe>
      </Layout>

    )
  }


export default RegisterIndex
